export function getSource() {
    let sourceStr = ''
    // 取出请求参数
    let hasKeyWordId = false
    let query = window.location.search
    if (query.indexOf('e_keywordid=') > -1) {
        // 如果请求参数中带有e_keywordid
        hasKeyWordId = true
    }
    if (query) {
        let queryList = []
        queryList = query.split('&')
        // ['aa=1','bb=2'] => [{name: 'aa', key:1}, { name: 'bb', key:2 }]
        let newList = queryList.map((item) => {
            let value = item.split('=')
            return {
                name: value[0],
                key: value[1]
            }
        })
        if (hasKeyWordId) {
            // 如果付费
            newList.forEach((item) => {
                if (item.name === 'source') {
                    sourceStr = item.key + '付费'
                }
            })
            // 如果没有source，则默认传baidu付费
            if (!sourceStr) {
                sourceStr = 'baidu付费'
            }
        } else {
            // 如果没有付费
            newList.forEach((item) => {
                if (item.name === 'source') {
                    sourceStr = item.key
                }
            })
        }
    }
    return sourceStr
}

export function isMobile() {
    let userAgent = navigator.userAgent,
        agents = [
            'Android',
            'iPhone',
            'SymbianOS',
            'Windows Phone',
            'iPad',
            'iPod'
        ]
    return agents.some((i) => {
        return userAgent.includes(i)
    })
}
