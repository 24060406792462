<template>
    <el-dialog
        title="提示"
        v-model="dialogFormVisible"
        custom-class="registered-form"
        width="560px"
        :destroy-on-close="true"
        :fullscreen="isMobile() ? true : false"
        top="2%"
        :before-close="handleClose"
    >
        <section class="modal-form mobile-register-form">
            <p class="title">注册百家云 SCRM</p>
            <p class="tip">免费体验</p>
            <div class="img-del" @click.stop="handleClose">
                <img
                    src="@/assets/img/icon-close.png"
                    alt=""
                    width="12"
                    height="12"
                />
            </div>
            <el-form
                label-width="80px"
                label-position="top"
                :model="form"
                :rules="rules"
                ref="formRef"
            >
                <el-form-item label="企业名称" prop="partnerName">
                    <el-input
                        size="small"
                        v-model="form.partnerName"
                        maxlength="45"
                        style="min-width: 180px"
                        autocomplete="off"
                        placeholder="请输入真实企业名称"
                    >
                        <template #prefix>
                            <img
                                class="unfocus"
                                style="width: 15px"
                                :src="require('@/assets/img/input_company.png')"
                            />
                            <img
                                class="focus"
                                style="width: 15px"
                                :src="
                                    require('@/assets/img/input_company_2.png')
                                "
                            />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="您的姓名" prop="personName">
                    <el-input
                        size="small"
                        style="min-width: 180px"
                        v-model.trim="form.personName"
                        autocomplete="off"
                        maxlength="20"
                        placeholder="请输入真实姓名"
                    >
                        <template #prefix>
                            <img
                                class="unfocus"
                                style="width: 15px"
                                :src="require('@/assets/img/input_user.png')"
                            />
                            <img
                                class="focus"
                                style="width: 15px"
                                :src="require('@/assets/img/input_user_2.png')"
                            />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="contactInfo">
                    <el-input
                        size="small"
                        style="min-width: 180px"
                        v-model.trim="form.contactInfo"
                        autocomplete="off"
                        maxlength="20"
                        placeholder="请输入真实手机号码"
                    >
                        <template #prefix>
                            <img
                                class="unfocus"
                                style="width: 15px"
                                :src="require('@/assets/img/input_phone.png')"
                            />
                            <img
                                class="focus"
                                style="width: 15px"
                                :src="require('@/assets/img/input_phone_2.png')"
                            />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="手机验证码" prop="verifyCode">
                    <el-input
                        size="small"
                        v-model.trim="form.verifyCode"
                        autocomplete="off"
                        class="verify-code"
                        maxlength="6"
                        placeholder="请输入手机验证码"
                    >
                        <template #prefix>
                            <img
                                class="unfocus"
                                style="width: 15px"
                                :src="require('@/assets/img/verify_code1.png')"
                            />
                            <img
                                class="focus"
                                style="width: 15px"
                                :src="require('@/assets/img/verify_code2.png')"
                            />
                        </template>
                    </el-input>
                    <el-button
                        :disabled="timerCount ? true : false"
                        class="verify-code-button"
                        @click="sendMessage(true)"
                        >{{
                            timerCount > 0 ? timerCount + '秒' : '获取验证码'
                        }}</el-button
                    >
                </el-form-item>
            </el-form>
        </section>
        <template #footer>
            <div class="registered-button">
                <el-button @click="submit" v-loading="loading" type="primary"
                    >立即提交</el-button
                >
            </div>
        </template>
    </el-dialog>

    <el-dialog
        custom-class="submit-success"
        v-model="dialogSuccessVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="560px"
        :fullscreen="isMobile() ? true : false"
        top="5%"
        center
    >
        <section>
            <span class="img-close" @click.stop="closeSuccessTip"
                ><img
                    src="@/assets/img/icon-close.png"
                    alt=""
                    width="12px"
                    height="12px"
            /></span>
            <p class="title">提交成功！</p>
            <p class="tip">
                您的百家云SCRM专属客服将在<span>1个工作日内</span>联系您并为您提供咨询服务
            </p>
            <el-button @click="experience">立即体验</el-button>
        </section>
    </el-dialog>
    <!--
    <el-button class="zaixian-zixun" @click="zixun">
        <img src="@/assets/img/advisory.png" style="width: 30px" />
        <span>在线咨询</span>
    </el-button>
    <el-button class="qitian-shiyong register">
        <img style="width: 30px" src="@/assets/img//free.png" />
        <span>免费试用</span>
    </el-button>
    -->
    <el-popover
        placement="left"
        title=""
        :width="400"
        trigger="click"
        content=""
    >
        <template #reference>
            <div class="service-btn pc">
                <div>
                    <img
                        style="width: 29px; margin-bottom: 3px"
                        src="@/assets/img/icon-service.svg"
                    />
                    <span>联系我们</span>
                </div>
            </div>
        </template>
        <div class="qrcode-wrap">
            <img src="@/assets/img/qr-code2.png?v=1" width="108" height="108" />
            <div>
                <h4>您好，我是您的专属管家</h4>
                <div>
                    想了解更多，欢迎添加管家企业微信，在使用百家云SCRM的过程中遇到任何问题我都会帮你解决
                </div>
            </div>
        </div>
    </el-popover>
</template>

<script>
import $ from 'jquery'
import { ref, reactive, onMounted } from 'vue'
import './assets/js/share.js'
import { getSource, isMobile } from './assets/js/tools'
let timer = 0
export default {
    name: 'App',
    components: {},
    setup() {
        let dialogFormVisible = ref(false)
        const formRef = ref()
        let loading = ref(false)
        let dialogSuccessVisible = ref(false)
        let sourceStr = getSource()
        let slides = []
        let timerCount = ref(0)
        let rules = {
            personName: [
                {
                    required: true,
                    message: '您的名字不能为空',
                    trigger: ['cahnge', 'blur']
                }
            ],
            contactInfo: [
                {
                    required: true,
                    message: '手机号码不能为空',
                    trigger: ['cahnge', 'blur']
                }
            ],
            partnerName: [
                {
                    required: true,
                    message: '企业名称不能为空',
                    trigger: ['cahnge', 'blur']
                }
            ],
            verifyCode: [
                {
                    required: true,
                    message: '验证码不能为空',
                    trigger: ['cahnge', 'blur']
                }
            ]
        }
        let form = reactive({
            personName: '',
            contactInfo: '',
            partnerName: '',
            verifyCode: ''
        })

        /**
         * 发送短信
         */
        function sendMessage(isStartcutdown) {
            if (!form.contactInfo) {
                alert('请填写手机号')
                return
            }
            clearTimeout(timer)
            // 开始计时
            if (isStartcutdown) {
                timerCount.value = 60
                sendVerifyCode()
                setTimeout(() => {
                    sendMessage(false)
                }, 1000)
                return
            }
            // 计时结事了
            if (timerCount.value <= 0 && !isStartcutdown) {
                clearTimeout(timer)
                return
            }
            timerCount.value--
            timer = setTimeout(() => {
                sendMessage(false)
            }, 1000)
        }

        function handleClose() {
            dialogFormVisible.value = false
        }
        function ipad(num, length = 2) {
            let arr = new Array(length - String(num).length + 1)
            return arr.join('0') + num
        }
        /**
         * 生成token
         */
        const generateToken = () => {
            let timeArr = String(new Date().getTime()).split('')
            timeArr = timeArr.concat(
                String(ipad(Math.ceil(Math.random() * 10000), 4)).split('')
            )
            let length = timeArr.length
            timeArr.splice(
                length - 2,
                1,
                ...timeArr.splice(0, 1, timeArr[length - 2])
            )
            timeArr.splice(
                length - 1,
                1,
                ...timeArr.splice(1, 1, timeArr[length - 1])
            )
            return btoa(timeArr.join(''))
        }

        function submit() {
            formRef.value.validate().then(async () => {
                let params = {
                    curPage: window.location.href,
                    source: sourceStr,
                    token: generateToken(),
                    ...form
                }
                if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) {
                    params.clientType = 1
                } else {
                    params.clientType = 0
                }
                loading.value = true
                $.ajax({
                    type: 'post',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(params),
                    url: '/intention/add.json',
                    success: function (res) {
                        if (res.code === 0) {
                            dialogFormVisible.value = false
                            loading.value = false
                            $('html,body').css('overflow', 'visible')
                            $('htm,body').css('height', '100%')
                            openSuccessTip()
                        } else {
                            let data = res.message
                            alert(data)
                            loading.value = false
                        }
                    },
                    error(err) {
                        loading.value = false
                        $('html,body').css('overflow', 'visible')
                        $('htm,body').css('height', '100%')
                        if (err.responseJSON) {
                            alert(err.responseJSON.msg)
                        } else {
                            alert('系统异常')
                        }
                    }
                })
            })
        }
        function sendVerifyCode() {
            let params = {
                mobile: form.contactInfo.trim(),
                token: generateToken()
            }
            $.ajax({
                type: 'post',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(params),
                url: '/sms/getVerificationCode.json',
                success: function (res) {
                    if (res.code === 0) {
                    } else {
                        alert(res.data.message || '系统异常')
                        loading.value = false
                    }
                },
                error(err) {
                    alert(err.data.message || '系统异常')
                }
            })
        }
        function openSuccessTip() {
            dialogSuccessVisible.value = true
        }
        function zixun() {
            window._MEIQIA('showPanel')
        }
        function closeSuccessTip() {
            dialogSuccessVisible.value = false
        }
        function experience() {
            window.open('https://kinyun.cn/register')
            dialogSuccessVisible.value = false
        }
        onMounted(() => {
            $(function () {
                function bindEvent() {
                    $('body')
                        .delegate('#login', 'click', function () {
                            window.open(
                                window.location.protocol + '//kinyun.cn'
                            )
                        })
                        .delegate('.register', 'click', function () {
                            dialogFormVisible.value = true
                        })
                        .delegate('.consult', 'click', function () {
                            window._MEIQIA('showPanel')
                        })
                        .delegate('.tabs li', 'click', function (e) {
                            let target = $(e.currentTarget)
                            let slideNum = target.parent().attr('num')
                            let tabNum = target.attr('num')
                            target.parent().find('li').removeClass('active')
                            target.addClass('active')
                            slides[+slideNum].goToSlide(tabNum)
                            slides[+slideNum].stopAuto()
                            slides[+slideNum].startAuto()
                        })

                        .delegate('.tabs2 li', 'click', function (e) {
                            let target = $(e.currentTarget)
                            let slideNum = target.parent().attr('num')
                            let tabNum = target.attr('num')
                            target.parent().find('li').removeClass('active')
                            target.addClass('active')
                            slides[+slideNum].goToSlide(tabNum)
                            slides[+slideNum].stopAuto()
                            slides[+slideNum].startAuto()
                        })

                        .delegate('.prev img', 'click', function (e) {
                            // let parent = $('.slider-wrap3')
                            // let children = parent.children()
                            // let tabNum = 0
                            // let nextNode = children
                            // while (nextNode.attr('aria-hidden') !== 'false') {
                            //     tabNum++
                            //     nextNode = nextNode.next()
                            // }
                            // if (tabNum === 1) {
                            //     tabNum = 2
                            // } else {
                            //     tabNum--
                            //     tabNum--
                            // }
                            // slides[3].goToSlide(tabNum)
                            slides[3].goToPrevSlide()
                            slides[3].stopAuto()
                            slides[3].startAuto()
                        })

                        .delegate('.next img', 'click', function (e) {
                            slides[3].goToNextSlide()
                            slides[3].stopAuto()
                            slides[3].startAuto()
                        })

                        .delegate('.tel', 'click', function () {
                            location.href = 'tel:' + '4000-369-100'
                        })

                    $(window).on('scroll', () => {
                        let pageScrollY =
                            window.scrollY || document.documentElement.scrollTop
                        if (pageScrollY > 50) {
                            $('.top').addClass('scrolled')
                        } else {
                            $('.top').removeClass('scrolled')
                        }
                    })
                }
                function init() {
                    bindEvent()
                    if (isMobile()) {
                        ;[0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((num) => {
                            $('.m-slider-wrap' + num).bxSlider({
                                pager: true,
                                controls: false,
                                auto: true,
                                useCSS: false,
                                tickerHover: true,
                                autoHover: true,
                                touchEnabled: false,
                                stopAutoOnClick: true
                            })
                        })
                    } else {
                        ;[0, 1, 2, 3, 4, 5, 6, 7, 8].forEach((num) => {
                            slides[num] = $('.slider-wrap' + num).bxSlider({
                                pager: false,
                                controls: false,
                                auto: true,
                                useCSS: false,
                                tickerHover: true,
                                autoHover: true,
                                stopAutoOnClick: true,
                                onSlideNext($slideElement, oldIndex, newIndex) {
                                    let $tabLi = $slideElement
                                        .parents('.slider')
                                        .find('.tabs li')
                                    $tabLi.removeClass('active')
                                    $tabLi.eq(newIndex).addClass('active')
                                }
                            })
                        })
                    }
                    $('.banner-slider').bxSlider({
                        pager: true,
                        controls: false,
                        auto: true,
                        useCSS: false,
                        tickerHover: true,
                        autoHover: true,
                        touchEnabled: false,
                        stopAutoOnClick: true
                    })
                }
                init()
            })
        })
        return {
            dialogFormVisible,
            handleClose,
            rules,
            form,
            submit,
            formRef,
            loading,
            closeSuccessTip,
            experience,
            isMobile,
            dialogSuccessVisible,
            zixun,
            sendMessage,
            timerCount
        }
    }
}
</script>

<style lang="scss">
@import './assets/scss/home.scss';
</style>
