let $ = require('jquery')
$(document).ready(function () {
    let wx = window.wx
    const href = location.href
    // const { data } = await get('/wechatapi/signature.json', {
    //     url: href
    // })
    function wxF(data) {
        const cfg = {
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            signature: data.signature,
            jsApiList: [
                'updateAppMessageShareData',
                'updateTimelineShareData',
                'onMenuShareAppMessage', // 旧的接口，即将废弃
                'onMenuShareTimeline' // 旧的接口，即将废弃
            ]
        }
        wx.config(cfg)
        let shareConfig = {
            title: '百家云SCRM',
            desc: '百家云SCRM一站式客户运营平台，让您的客户管理更简单、更安全',
            link: location.href,
            imgUrl: 'http://cdn.kinyun.cn/tool/640.png'
        }
        wx.ready(() => {
            wx.onMenuShareTimeline({
                title: shareConfig.title, // 分享标题
                desc: shareConfig.desc, // 分享描述
                link: shareConfig.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareConfig.imgUrl, // 分享图标
                success: function (res) {
                    // 这里是回调函数
                    console.log(res)
                }
            })
            wx.onMenuShareAppMessage({
                title: shareConfig.title, // 分享标题
                desc: shareConfig.desc, // 分享描述
                link: shareConfig.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: shareConfig.imgUrl, // 分享图标
                success: function (res) {
                    // 设置成功
                    console.log(res)
                }
            })
        })
    }
    $.ajax({
        type: 'post',
        contentType: 'application/json',
        dataType: 'json',
        url: '/mp/signature.json',
        data: JSON.stringify({ url: href }),
        success: function (res) {
            if (res.code === 0) {
                wxF(res.data)
            } else {
                alert(res.message)
            }
        }
    })

    document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault()
        }
    })
    var lastTouchEnd = 0
    document.addEventListener(
        'touchend',
        function (event) {
            var now = new Date().getTime()
            if (now - lastTouchEnd <= 300) {
                event.preventDefault()
            }
            lastTouchEnd = now
        },
        false
    )
})
